// 'o9n' is numeronym for 'obfuscation'

const logColor = {
  cyan: 'background: cyan; color: black',
  green: 'background: green; color: white',
  grey: 'background: grey; color: white',
  magenta: 'background: magenta; color: white'
}

class BarridoDesenladrillador {
  constructor (padre) {
    this.selectorBase = '[data-reduce][data-reuse][data-recycle], [class*="trix1314-"]'
    this.selectorSiProcesado = '[data-la1314="done"]'

    const ladrillos = padre.querySelectorAll(this.selectorAntesDeProcesar)

    if (window.o9nDebug) {
      console.log('%cBarrido: iniciado para', logColor.magenta, padre)
      if (ladrillos.length) {
        console.log('%cBarrido: Procesando %d elementos', logColor.green, ladrillos.length, ladrillos)
      } else {
        console.log('%cBarrido: Nada para procesar', logColor.grey)
      }
    }

    ladrillos.forEach(ladrillo => this.procesarLadrillo(ladrillo))
  }

  get selectorAntesDeProcesar () {
    return this.selectorBase.split(',').map(selector => `${selector}:not(${this.selectorSiProcesado})`).join(',')
  }

  marcarComoProcesado (node) {
    node.classList.remove(this.recuperarClassName(node))
    node.removeAttribute('data-reduce')
    node.removeAttribute('data-reuse')
    node.removeAttribute('data-recycle')
    node.dataset.la1314 = 'done'
  }

  desenladrillar (enladrillado) {
    if (enladrillado.indexOf('--') > 0) {
      enladrillado = enladrillado.split('--').join('\n')
    }

    return window.atob(enladrillado.split('').reverse().join(''))
  }

  procesarLadrillo (ladrillo) {
    const desencriptado = this.desenladrillar(this.recuperarHash(ladrillo))
    const url = desencriptado.startsWith('/') ? `${location.origin}${desencriptado}` : desencriptado

    ladrillo.addEventListener('click', event => {
      event.preventDefault()
      if (window.setObfucatedDataToDatalayer) window.setObfucatedDataToDatalayer(event.target, url)
      window.location.href = url
    })
    this.marcarComoProcesado(ladrillo)
  }

  recuperarHashDeDataset (node) {
    return node.dataset.reduce + node.dataset.reuse + node.dataset.recycle
  }

  recuperarHashDeClassName (node) {
    return this.recuperarClassName(node)?.replace('trix1314-', '')
  }

  recuperarClassName (node) {
    return Array.from(node.classList).filter(clase => clase.indexOf('trix1314-') === 0)[0]
  }

  recuperarHash (node) {
    return this.recuperarHashDeClassName(node) || this.recuperarHashDeDataset(node)
  }
}

document.addEventListener('DOMContentLoaded', _ => new BarridoDesenladrillador(document.body))

const observerTarget = document.body
const observerConfig = { childList: true, subtree: true }
const observer = new MutationObserver(mutations => {
  mutations.forEach(mutation => {
    if (mutation.type === 'childList' && mutation.addedNodes.length) {
      const arrayOfElements = Array.from(mutation.addedNodes)
                                   .filter(node => node.nodeType === node.ELEMENT_NODE)

      if (window.o9nDebug) {
        console.log('%cObserver: Detectados nuevos nodos en DOM', logColor.cyan, mutation.addedNodes)
        if (arrayOfElements.length) {
          console.log('%cObserver: Procesando %d elementos', logColor.green, arrayOfElements.length, arrayOfElements)
        } else {
          console.log('%cObserver: Nada para procesar', logColor.grey)
        }
      }

      arrayOfElements.forEach(node => new BarridoDesenladrillador(node.parentElement || node))
    }
  })
})

observer.observe(observerTarget, observerConfig)
